<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackList">{{ type }}订单</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackDetail">订单详情</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>发货明细</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">商品信息</div></a-col>
        <a-table
          class="table"
          :data-source="commodityData"
          :columns="commodityColumns"
          :pagination="false"
          :loading="loading"
          row-key="skuId"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div v-if="!record.skuImages"></div>
            <img
              v-else-if="!record.skuImages[0].thumbUrl"
              :src="imageBaseUrl + record.skuImages[0].fileUrl"
              :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
              alt="产品图片"
              class="img-sku"
            />
            <img
              v-else
              :src="imageBaseUrl + record.skuImages[0].thumbUrl"
              :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
              alt="产品图片"
              class="img-sku"
            />
          </div>
        </a-table>
      </a-row>
      <a-row>
        <a-col><div class="info-title">商品序列号</div></a-col>
      </a-row>
      <a-table
        class="table"
        :data-source="tblData"
        :columns="tblColumns"
        :pagination="false"
        :loading="loading"
        row-key="skuSn"
      >
        <template slot="goodsSn" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
        <template slot="batchNo" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            v-if="
              record.deliveredGoodsList && record.deliveredGoodsList.length > 0
            "
            @click="onCheckPart(record.deliveredGoodsList)"
          >
            查看配件
          </a>
        </template>
      </a-table>

      <a-modal
        title="配件"
        :visible="partVisible"
        :footer="null"
        width="60%"
        @cancel="partVisible = false"
      >
        <a-table
          class="table"
          :data-source="partTblData"
          :columns="partColumns"
          :pagination="false"
          row-key="skuSn"
        >
          <template slot="goodsSn" slot-scope="text">
            {{ text ? text : "-" }}
          </template>
          <template slot="batchNo" slot-scope="text">
            {{ text ? text : "-" }}
          </template>
        </a-table>
      </a-modal>
    </section>
  </a-layout-content>
</template>

<script>
import { onDeliverDetail } from "../../services/OrderService";
import { imageBaseUrl } from "../../services/HttpService";

const tblColumns = [
  {
    title: "序号",
    dataIndex: "index"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: {
      customRender: "goodsSn"
    }
  },
  {
    title: "批次号",
    dataIndex: "batchNo",
    scopedSlots: {
      customRender: "batchNo"
    }
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" }
  }
];
const partColumns = [
  {
    title: "序号",
    dataIndex: "index"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: {
      customRender: "goodsSn"
    }
  },
  {
    title: "批次号",
    dataIndex: "batchNo",
    scopedSlots: {
      customRender: "batchNo"
    }
  },
  {
    title: "数量",
    dataIndex: "quantity"
  }
];
const commodityColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" }
  },
  {
    title: "本批发货数量",
    dataIndex: "quantityShipped"
  },
  {
    title: "本批装运总体积（m³）",
    dataIndex: "packVolume"
  },
  {
    title: "本批商品总重量（kg）",
    dataIndex: "skuWeight"
  }
];
export default {
  data() {
    return {
      imageBaseUrl,
      tblData: [],
      tblColumns,
      loading: false,
      logisticsId: this.$route.params.logisticsId,
      orderSn: this.$route.params.orderSn,
      commodityData: [],
      commodityColumns,
      type: "",
      partVisible: false,
      partColumns,
      partTblData: []
    };
  },
  mounted() {
    this.onDetail();
  },
  methods: {
    onBackList() {
      if (this.type === "未交付")
        this.$router.push({ name: "UndeliveredOrder" });
      else {
        this.$router.push({ name: "DeliveredOrder" });
      }
    },
    onDetail() {
      this.loading = true;
      const params = {
        logisticsId: this.logisticsId,
        orderSn: this.orderSn
      };
      onDeliverDetail(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const data = resp.data.data;
          const deliveredGoodsVOList = data.deliveredGoodsVOList;
          if (deliveredGoodsVOList.length > 0) {
            deliveredGoodsVOList.forEach((item, index) => {
              item.index = index + 1;
            });
          }
          this.tblData = deliveredGoodsVOList;
          const orderSkuVOList = data.orderSkuVOList;
          const list = [];
          if (orderSkuVOList.length > 0) {
            orderSkuVOList.forEach(item => {
              item.keyProps = JSON.parse(item.keyProps);
              for (const key in item.keyProps) {
                list.push(key);
              }
            });
          }
          const arr = new Set();
          list.forEach(item => {
            arr.add(item);
          });
          const a = Array.from(arr);
          if (a.length > 0) {
            a.forEach(item => {
              const column = {
                title: item,
                dataIndex: `keyProps.${item}`
              };
              const tblColumn = [...this.commodityColumns];
              if (tblColumn.indexOf(column) === -1) {
                tblColumn.splice(1, 0, column);
              }
            });
          }
          this.commodityData = orderSkuVOList;
        }
      });
    },
    onBackDetail() {
      if (this.type === "未交付") {
        this.$router.push({
          name: "UndeliveredDetail",
          params: { procureOrderSn: this.orderSn }
        });
      } else {
        this.$router.push({
          name: "DeliveredDetail",
          params: { orderSn: this.orderSn }
        });
      }
    },
    onCheckPart(list) {
      list.forEach((item, index) => {
        item.index = index + 1;
      });
      this.partTblData = list;
      console.log(this.partTblData);
      this.partVisible = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "UndeliveredDetail") {
        vm.type = "未交付";
      } else {
        vm.type = "已交付";
      }
    });
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 80px 20px;
  background-color: white;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.table {
  margin: 20px 0;
}
.img-sku {
  width: 100px;
  height: 100px;
}
</style>
